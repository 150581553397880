import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { loaderColor, TITLE } from 'brand/telepagos/proyect';
import { Spinkit } from 'ng-http-loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public spinkit = Spinkit;
  title = TITLE;
  loaderColor = loaderColor;

  rutasIgnoradasLoader: string[] = ['cliente/fotos', 'cliente/transferencias', 'cliente/repet', 'cliente/alicuotas', 'cliente/cuenta_virtual/logs']

  public constructor(private titleService: Title) {
    titleService.setTitle(this.title)
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
