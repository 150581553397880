import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AutentificacionService } from '@services/autentificacion.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor( 
    private autentificacionService:AutentificacionService,
   ){}

  canActivate(){
    
    if(this.autentificacionService.estaLogueado()){
        
      return true;
        
    }else{
      this.autentificacionService.expulsar()
      return false;
    }
  }
  
}
