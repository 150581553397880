import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AutentificacionService } from '@services/autentificacion.service';

@Injectable({
  providedIn: 'root'
})
export class YaEstaLogueadoGuard implements CanActivate {

  constructor(private router:Router, private autentificacionService:AutentificacionService ){}
  
  canActivate() {

    if (!this.autentificacionService.estaLogueado()) {

      return true;

    } else {

      this.router.navigate(['']);
      return false;
    }
  }
  
}
