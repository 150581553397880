//Angular Modules
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
//Routing
import {AppRoutingModule} from '@/app-routing.module';
//App built-in components
import {AppComponent} from './app.component';

//Material
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
//Store
import { StoreModule } from '@ngrx/store';
import { uiReducer } from './store/ui/reducer';
//ngBoostrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//Inicializador
import { AutentificacionService } from '@services/autentificacion.service';
//Interceptors
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
//Directives
import { OnlyNumberDirective } from './directives/only-number.directive';
import { AppAutofocusDirective } from './directives/app-autofocus.directive';
//Masks
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskModule } from 'ngx-mask';
//Mensajes
import {ToastrModule} from 'ngx-toastr';
//Loader
import { NgHttpLoaderModule } from 'ng-http-loader';

registerLocaleData(localeEn, 'en-EN');

export function initApp(autentificacionService: AutentificacionService) {
    return () => autentificacionService.getUsuarioPromise()
  }

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        StoreModule.forRoot({ui: uiReducer}),
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-bottom-center',
            preventDuplicates: true,
            closeButton: true,
            enableHtml: true,
            iconClasses : {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning-custom',
            }
        }),
        NgbModule,
        NgHttpLoaderModule.forRoot(),
        CurrencyMaskModule,
        NgxMaskModule.forRoot(),
        MatDialogModule
    ],
    exports : [
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        // { provide: LOCALE_ID, useValue: 'fr-FR'},
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory:initApp,multi:true,deps:[AutentificacionService]}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
